<template>
  <div class="testArea fill-height">
    <v-app>
      <v-content>
        <v-dialog v-model="captchaModal" width="355px">
          <v-card>
            <v-card-text class="pt-6">
              <div class="text-center">
                <vue-recaptcha
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    :sitekey="captchaSiteKey"
                    size="normal"
                    v-if="loadCaptcha"
                    style="margin: 0px auto !important;"
                ></vue-recaptcha>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-app-bar elevation="0" :color="'transparent'" absolute v-if="$vuetify.breakpoint.mdAndUp">
          <v-container>
            <v-row class="d-flex">
              <v-col>
                <v-btn elevation="0" class="registerBtn" :to="`/${$route.params.lang}/preorder/sign/68`"
                       style="text-decoration: none">
                  رزرو وقت مشاوره
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-center flex-grow-1  mt-2">
                <router-link :to="{name:'dashboard'}">
                  <img :src="require('../../../assets/simialogopng.png')" style="width: 125px;"
                       v-if="getPlatformInfo('id') == 3">
                  <p v-else-if="getPlatformInfo('id')">{{ getPlatformTitle() }}</p>
                </router-link>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="flex-grow-1">
              </v-col>
            </v-row>
          </v-container>
        </v-app-bar>
        <v-app-bar v-else color="white" absolute class="menuMob">
          <v-spacer></v-spacer>
          <router-link :to="{name:'dashboard'}">
            <img :src="require('../../../assets/simialogopng.png')" style="width: 125px;"
                 v-if="getPlatformInfo('id') == 3">
            <p v-else-if="getPlatformInfo('id')" class="mt-3">{{ getPlatformTitle() }}</p>
          </router-link>
          <v-spacer></v-spacer>
        </v-app-bar>
        <div class="circleOne"></div>
        <div class="circleTwo"></div>
        <div class="circleThree"></div>
        <v-container>
          <div style="position: relative " class="">
            <div class="testStepper  px-2 py-2 " style="z-index: 2">
              <div v-if="testInfo == null">
                <div class="startTest text-center mt-10">
                  <v-progress-circular color="#3CBE8B" indeterminate></v-progress-circular>
                  <p class="mt-3 font-weight-bold">
                    در حال آماده سازی تست
                  </p>
                </div>
              </div>
              <div v-else>
                <div class="startTest">
                  <div class="text-center">
                    <img :src="`${assetUrl}/${testInfo.image}`" width="120px" alt="" v-if="testInfo.image">
                    <img src="../../../assets/tests/beckTestIcon.svg" width="120px" alt="" v-else>
                    <p class="titleTest">
                      {{ testInfo.title_fa }}
                    </p>
                    <p class="descTest">
                      {{ testInfo.description_fa }}
                    </p>
                    <div class="testInfo">
                      <v-row>
                        <v-col cols="4" lg="4">
                          <img src="../../../assets/tests/questionCount.svg" width="60px" alt="">
                          <div class="testInfoTitle">
                            تعداد سوالات
                          </div>
                          <div class="testInfoDetails questionCount">
                            {{ countQuestions }}
                          </div>
                        </v-col>
                        <v-col cols="4" lg="4">
                          <img src="../../../assets/tests/agePirod.svg" width="60px" alt="">
                          <div class="testInfoTitle">
                            رده سنی
                          </div>
                          <div class="testInfoDetails">
                            نوجوان - بزرگسال
                          </div>
                        </v-col>
                        <v-col cols="4" lg="4">
                          <img src="../../../assets/tests/times.svg" width="60px" alt="">
                          <div class="testInfoTitle">
                            زمان مورد نیاز
                          </div>
                          <div class="testInfoDetails " id="estimateTestTime">
                            {{ estimateTime() }} دقیقه
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                    <v-btn class="startTestBtn" @click="startTest" elevation="0">
                      شروع آزمون
                    </v-btn>
                  </div>
                </div>
                <div class="questionsArea">
                  <v-fade-transition>
                    <v-chip v-if="testStatus == 'inProgress'">
                      سوال
                      {{ activeQuestion + 1 }} از {{ countQuestions }}
                    </v-chip>
                  </v-fade-transition>
                  <div class="deActiveQuestions questionsStep" :class="'question'+i" v-for="(item , i ) in questions"
                       :key="i">
                    <p class="">
                      {{ item.title_fa }}
                    </p>
                    <div v-if="item.image_path" class="questionImage ">
                      <img :src="assetUrl + item.image_path" alt="">
                    </div>

                    <v-radio-group v-model="answer[item.id]" @change="nextQuestions">
                      <v-radio v-for="(option , i ) in item.options" :key="i" :value="option.id" color="green" class="my-2">
                        <template v-slot:label style="text-align: left !important;">
                                                                        <span style="text-align: right;font-size: 13px"
                                                                              class="mt-1"
                                                                              :id="`option_${option.id}`">
                                                                          {{ option.title_fa }}
                                                                        </span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                  <!--                  <div class="registerLogin   justify-space-around fill-height">-->
                  <!--                    <div style="width: 400px">-->
                  <!--                      <v-window v-model="loginwindow" :reverse="this.$store.getters.isRTL" touchless>-->
                  <!--                        <v-window-item :value="1" class="">-->
                  <!--                          <LoginComponent @changeWindow="updateWindow"-->
                  <!--                                          :GALogin="'ga_login_new_preorder'"-->
                  <!--                                          preorderLogin="true"-->
                  <!--                                          @userLoggedIn="afterLogin"-->
                  <!--                                          :showRegisterLabel="true"-->
                  <!--                                          :subtitle="$t('onBoarding.titleLogin')"></LoginComponent>-->
                  <!--                        </v-window-item>-->
                  <!--                        <v-window-item :value="2">-->

                  <!--                          <RegisterUserTest-->
                  <!--                              @changeWindow="updateWindow"-->
                  <!--                              :GASignup="'ga_signup_psytest'"-->
                  <!--                              preorderLogin="true"-->
                  <!--                              @userLoggedIn="afterLogin"-->
                  <!--                              wizard="true"-->
                  <!--                              :from="'PSYTEST'"-->
                  <!--                              :title="$t('onBoarding.titleLogin')"-->
                  <!--                          ></RegisterUserTest>-->
                  <!--                          <div class="text-center" @click="getInterpretation">-->
                  <!--                            به صورت نشناس تست میزنم-->
                  <!--                          </div>-->
                  <!--                          &lt;!&ndash;                  @userLoggedInAfterRegister="userLoggedIn"&ndash;&gt;-->

                  <!--                        </v-window-item>-->
                  <!--                      </v-window>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="gotoInterpretation">
                    در حال دریافت تفسیر
                  </div>
                  <div class="actionArea d-flex " v-if="testStatus == 'inProgress'">
                    <div class="d-flex" style="cursor:pointer;">
                      <v-btn text class="mt-5 testBtn prevBtn" small fab elevation="0" @click="prevQuestion"
                             :disabled="activeQuestion == 0">
                        <v-icon>mdi-menu-right</v-icon>
                      </v-btn>
                      <div class="mt-7 mr-2" @click="activeQuestion != 0 ? prevQuestion() : ''">قبلی</div>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="d-flex" @click="nextQuestions" style="cursor: pointer">
                      <div class="mt-7 ml-2">بعدی</div>
                      <v-btn text class="mt-5 nextBtn  testBtn" small fab elevation="0">
                        <v-icon>mdi-menu-left</v-icon>
                      </v-btn>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="authStepper  " :style="authStepperVisibility && 'margin: 100px auto 0px auto'"
                 style="z-index: 2">
              <v-row class="pa-0">
                <v-col cols="12" class="pa-0" lg="6">
                  <div class=" d-flex flex-column fill-height justify-space-between"
                       :class="$vuetify.breakpoint.mdAndUp ? 'pa-12' : 'px-5 py-9'">
                    <div class="">
                      <v-window v-model="loginwindow" :reverse="this.$store.getters.isRTL" touchless>
                        <v-window-item :value="1" class="">
                          <LoginComponent @changeWindow="updateWindow"
                                          :GALogin="'ga_login_new_preorder'"
                                          preorderLogin="true"
                                          @userLoggedIn="afterLogin"
                                          :showRegisterLabel="true"
                                          :subtitle="$t('onBoarding.titleLogin')"></LoginComponent>
                        </v-window-item>
                        <v-window-item :value="2">
                          <RegisterUserTest
                              @changeWindow="updateWindow"
                              :GASignup="'ga_signup_psytest'"
                              preorderLogin="true"
                              @userLoggedIn="afterLogin"
                              wizard="true"
                              :from="'PSYTEST'"
                              :title="$t('onBoarding.titleLogin')"
                          ></RegisterUserTest>
                        </v-window-item>
                      </v-window>
                    </div>
                    <v-btn class="mb-3 text-center mx-4 anonymouslyBtn py-5" outlined color="grey"
                           style="border-radius: 7px"
                           @click="getInterpretationWithCaptcha">
                      تمایلی به ثبت‌نام ندارم
                    </v-btn>
                    <vue-recaptcha
                        ref="recaptcha"
                        @verify="onCaptchaVerified"
                        @expired="expiredCaptcha"
                        @error="onCaptchaError"
                        :sitekey="captchaSiteKey"
                        size="normal"
                        v-if="loadCaptcha"
                        style="margin: 0px auto !important;"
                    ></vue-recaptcha>
                  </div>
                </v-col>
                <v-col cols="12" class="pa-0" lg="6">
                  <div class="featureBox py-5  d-flex flex-column">
                    <div class="text-center titleFeatureBox   font-weight-bold"
                         :class="$vuetify.breakpoint.mdAndUp && 'pt-10'">
                      مزایای پنل کاربری در صورت ثبت نام
                    </div>
                    <v-spacer></v-spacer>
                    <div class="">
                      <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-fade-transition mode="out-in">
                          <img :src="require('../../../assets/tests/register/guest_dec1.png')" width="100%" alt=""
                               v-if="activeFeature == 1" :key="1">
                          <img :src="require('../../../assets/tests/register/guest_dec2.png')" width="100%" alt=""
                               v-if="activeFeature == 2" :key="2">
                          <img :src="require('../../../assets/tests/register/guest_dec3.png')" width="100%" alt=""
                               v-if="activeFeature == 3" :key="3">
                        </v-fade-transition>
                      </template>
                      <template v-else>
                        <div class="mobileImage">
                          <v-fade-transition mode="out-in">
                            <img :src="require('../../../assets/tests/register/guest_mob1.png')" width="100%" alt=""
                                 v-if="activeFeature == 1" :key="1">
                            <img :src="require('../../../assets/tests/register/guest_mob2.png')" width="100%" alt=""
                                 v-if="activeFeature == 3" :key="2">
                            <img :src="require('../../../assets/tests/register/guest_mob3.png')" width="100%" alt=""
                                 v-if="activeFeature == 2" :key="3">
                          </v-fade-transition>
                        </div>
                      </template>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="">
                      <div class="FeatureBoxOptions justify-space-between  d-flex  pb-10"
                           :class="$vuetify.breakpoint.mdAndUp ? 'pl-15' : 'px-7'">
                        <div class="feature " :class="activeFeature == 1 ? 'ActiveFeature' : 'deactiveFeature'">
                          دسترسی به همه تست‌ها
                          <div class="line"></div>
                        </div>
                        <div class="feature " :class="activeFeature == 2 ? 'ActiveFeature' : 'deactiveFeature'">
                          تست‌های انجام شده
                          <div class="line"></div>
                        </div>
                        <div class="feature " :class="activeFeature == 3 ? 'ActiveFeature' : 'deactiveFeature'">
                          تغییرات نتیجه تست‌ها
                          <div class="line"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

        </v-container>
      </v-content>
    </v-app>
  </div>
</template>

<script>
/*eslint-disable*/
import gsap from 'gsap';
import LoginComponent from '@/components/Auth/LoginTest'
import axios from "axios";
import {mapGetters} from "vuex";
import RegisterUserTest from '@/components/Auth/SignUpComponenOnboarding'
import {Hooper, Slide} from "hooper";
import 'hooper/dist/hooper.css';
import {VueRecaptcha} from 'vue-recaptcha';

export default {

  components: {
    RegisterUserTest, LoginComponent, Hooper,
    VueRecaptcha,
    Slide
  },
  data() {
    return {
      captchaModal: false,
      captchaSiteKey: '6LcAxKMhAAAAAKfZiweyJWsn89qmKB8M-ZeFFJpT',
      activeFeature: 2,
      activeQuestion: 0,
      loginwindow: 2,
      finalAnswer: [],
      answer: [],
      testStatus: 'Preparation',
      questions: [],
      testInfo: null,
      lock: false,
      prevLock: false,
      title: "مشاوره آنلاین روانشناسی",
      description: "مشاوره آنلاین روانشناسی",
      authStepperVisibility: false,
      loadCaptcha: true,
      captchaToken: null,
      isCaptchaverified: false,
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      profile: 'getUserInfo',
      test: 'userIsLoggedIn',
      assetUrl: 'testAssetUrl',
    }),
    countQuestions() {
      return this.questions.length
    },

  },
  mounted() {
    // this.$store.dispatch('checkLogin', ({redirect: false, logout: true}))
    this.getTestInfo()

    setTimeout(() => {

    })

    setInterval(() => {
      if (this.activeFeature == 3) {
        this.activeFeature = 1
      } else {
        this.activeFeature++
      }
    }, 3000)

  },
  methods: {
    onCaptchaVerified(token) {
      this.captchaToken = token
      this.isCaptchaverified = true
    },
    expiredCaptcha() {
      this.isCaptchaverified = false
    },
    onCaptchaError() {
      this.isCaptchaverified = false
      this.loadCaptcha = false
      this.$nextTick().then(() => {
        this.loadCaptcha = true
      })
    },
    getInterpretationWithCaptcha() {
      if (!this.isCaptchaverified) {
        this.$emitEvent('notify', {
          color: 'red',
          title: 'فیلد من ربات نیستم اجباری است',
        });
      } else {
        this.getInterpretation()
      }
    },
    estimateTime() {
      if (this.questions.length) {
        return Math.floor(this.questions.length * 15 / 60)
      }
    },
    getTestInfo() {
      // this.$emitEvent('setLoading', true)
      this.requesting('test', 'getTestInfo', {
        testID: this.$route.params.testID
      }).then(resp => {
        // console.log(resp.data[0])
        this.testInfo = resp.data.test
        this.title = this.testInfo.title_fa
        this.description = this.testInfo.description_fa
        this.questions = resp.data.questions

        if (this.$route.query.start == 'true') {
          this.startTest();
        }
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    updateWindow(variable) {
      this.loginwindow = variable
      // this.loginRegisterBtn = variable == 1 ? 'login' : 'register';
    },
    startTest() {
      gsap.to('.startTest', {duration: 0.5, x: 20, opacity: 0, display: 'none'}).then(() => {
        this.testStatus = 'inProgress'
        gsap.to('.questionsArea', {display: 'block'})
        gsap.fromTo(`.question${this.activeQuestion}`, {opacity: 0, x: -100}, {
          opacity: 1,
          display: 'block',
          x: 0
        }).then(() => {
        })
      })
    },
    nextQuestions() {
      if (this.checkItFill()) {
        if (!this.lock) {
          this.lock = true
          gsap.to(`.question${this.activeQuestion}`, {x: 20, opacity: 0, display: 'none', duration: 0.35}).then(() => {
            this.lock = false
            if (!this.isTestEnd()) {
              this.activeQuestion++;
              gsap.fromTo(`.question${this.activeQuestion}`, {opacity: 0, x: -20, duration: 0.35}, {
                opacity: 1,
                display: 'block',
                x: 0,
                duration: 0.35
              }).then(() => {
              })
            }
          })
        }
      }
    },
    prevQuestion() {
      if (!this.prevLock) {
        this.prevLock = true
        gsap.to(`.question${this.activeQuestion}`, {x: -20, opacity: 0, display: 'none', duration: 0.2}).then(() => {
          this.activeQuestion--;
          gsap.fromTo(`.question${this.activeQuestion}`, {opacity: 0, x: 100, duration: 0.35}, {
            opacity: 1,
            display: 'block',
            x: 0,
            duration: 0.35
          }).then(() => {
            this.prevLock = false
          })
        })
      }
    },
    checkItFill() {
      let question = this.questions[this.activeQuestion]
      if (this.answer[question.id]) {
        return true;
      }
      this.$emitEvent('notify', {color: 'red', title: 'پاسخ به سوال الزامی است', id: 'next_bt_notify'});
      return false
    },
    isTestEnd() {
      if (this.activeQuestion + 1 === this.countQuestions) {
        this.testStatus = 'end'
        if (!this.$store.getters.userIsLoggedIn) {
          this.renderLoginRegisterStep()
        } else {
          this.getInterpretation()
        }
        return true;
      }
      return false
    },
    renderLoginRegisterStep() {
      gsap.to('.testStepper', {opacity: 0, display: 'none'}).then(() => {
        this.authStepperVisibility = true


        gsap.fromTo(`.authStepper`, {opacity: 0, x: -100}, {
          opacity: 1,
          display: 'flex',
          x: 0,
          visibility: 'visible',
        })
      })

    },
    afterLogin() {
      gsap.to(`.authStepper`, {opacity: 0, x: 100, display: 'none'})
      gsap.fromTo(`.gotoInterpretation`, {opacity: 0, x: -100}, {
        opacity: 1,
        display: 'block',
        x: 0
      })
      this.getInterpretation()
    },
    sendWebEngagePaymentData(eventName, data) {
      // console.log(eventName, data, 'done');
      window.webengage.track(eventName, data);
    },
    answerTestAnonymously() {
      this.getInterpretation()
    },
    getInterpretation() {
      let keys = Object.keys(this.answer)
      keys.forEach((item) => {
        this.finalAnswer.push({
          "question_id": item,
          "option_id": this.answer[item]
        })
      })
      this.$emitEvent('setLoading', true)
      this.requesting('test', 'submitAnswer', {
        testID: this.$route.params.testID
      }, {
        answers: this.finalAnswer,
        token: this.captchaToken
      }).then(resp => {
        if (this.profile) {
          let userData = {
            email: this.profile.email,
            from: this.$route.params.testID,
          }
          if (resp.data[0].result.length == 1) {
            Object.assign(userData, {score: resp.data[0].result[0].score.sum})
          } else if (this.$route.params.testID == 21) {
            Object.assign(userData, {score: resp.data[0].result[0].score.sum})
          }
          console.log(userData)
          window.webengage.user.setAttribute('we_email', this.profile.email);
          this.sendWebEngagePaymentData('psychological_test', userData)
        }
        this.$router.push({
          name: 'interpretationTest',
          params: {testID: this.$route.params.testID, userTestId: resp.data[0].uuid}
        })
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      }).catch((er) => {
        if (er.response.data.status_code == -2500) {
          this.onCaptchaError()
        }
      })


    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .testInfo {
    width: 90% !important;
    margin: 40px auto;
  }

  .circleOne {
    width: 300px !important;
    height: 300px !important;
    top: -150px !important;
    left: -160px !important;
  }

  .questionsArea {
    width: 100% !important;
  }

  .featureBox {
    min-height: 360px !important;
  }

  .contentFeatureBox {
    top: 0px !important;
    transform: none !important;
    padding-top: 40px !important;
  }

  .authStepper {
    width: 90% !important;
  }

  .feature {
    font-size: 9px !important;
  }

  .line {
    width: 40px !important;
    height: 3px !important;
  }

  .questionImage img{
    width: 100% !important;
    margin: 0px auto;
  }
}

.g-recaptcha {
  width: 3000px; /* Set your desired width */
}

.mobileImage {
  /*background: red;*/
  height: 250px;
  /*overflow: hidden;*/
}

.feature {
  font-size: 13px;
}

.titleFeatureBox {
  font-size: 13px;
}

.anonymouslyBtn {
  color: grey;
  font-size: 11px;
  font-weight: bolder;
  cursor: pointer;
}

.sliderImage {
  border-radius: 10px;
}

.ActiveFeature {
  transition: all 0.3s;
}

.ActiveFeature .line {

  background: #3BB687;
  transition: all 0.3s;
}

.line {
  width: 50px;
  height: 5px;
  border-radius: 20px;
  margin: 10px auto 0px auto;
}

.authStepper {
  width: 80%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  visibility: hidden;
  /*display: none;*/
}

.deactiveFeature {
  color: #A5A5A5;
  font-size: 11px;
  transition: all 0.3s;
}

.menuMob {
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.16) !important;
}

.featureBox {
  min-height: 500px;
  /*background: url("../../../assets/businesspeople-working-finance-accounting-analyze-financi.png");*/
  background-position: center;
  position: relative;
  color: black;
}

.featureBox .wrapper {
  position: absolute;
  background: black;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: 0.7;
}

.contentFeatureBox {
  /*position: absolute;*/
  /*top: 50%;*/
  /*width: 100%;*/
  background: red;
  /*transform: translate(0, -50%);*/
  color: black;
  font-size: 13px;
}

.registerBtn {
  width: 132px !important;
  height: 34px !important;
  padding: 7px !important;
  border-radius: 10px;
  background-color: #46b1a1 !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff !important;
}

.loginBtn {
  width: 80px !important;
  height: 34px !important;
  margin: 2px 12px 0 0;
  padding: 5px 27px;
  border-radius: 10px;
}

.testArea {
  position: relative;
  overflow-x: hidden;
}

.circleOne {
  background: #dff8ff;
  z-index: 0;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  top: -300px;
  left: -300px;
  position: absolute;
  filter: blur(20px);
}

.circleTwo {
  position: absolute;
  background: #f0f1ff;
  bottom: -250px;
  left: -140px;
  width: 450px;
  height: 450px;
  border-radius: 50%;
  filter: blur(20px);
}

.circleThree {
  position: absolute;
  background: #e0fff6;
  bottom: 200px;
  right: -150px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(20px);
}

.main-container-c {
  font-size: 24px;
  display: flex;
  position: relative;
  justify-content: right;
  align-items: center;
  height: 100% !important;
  flex-direction: column;
  z-index: 3 !important;
  transition: all .3s linear;
}

.testStepper {
  border-radius: 20px;
  padding: 20px;
  overflow: hidden;
  position: absolute;
  top: 70px;
  left: 0px;
  right: 0px;
}


.main-background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f9ffff !important;
  z-index: 1;
}

.bg-circles {
  position: absolute;
  z-index: 2;
  filter: blur(46px);
  -webkit-filter: blur(46px);
}

.bg-circles.aqua-circle {
  top: -10%;
  left: -5%;
  width: 30%;
}

.bg-circles.green-circle {
  bottom: 12%;
  right: -10%;
  width: 20%;
}

.bg-circles.purple-circle {
  bottom: -15%;
  left: -5%;
  width: 20%;
}

.start-btn {
  height: 35px !important;
  background: #3CBE8B !important;
  color: white;
  border-radius: 10px;
  transition: background-color .3s linear;
  font-weight: bolder;
  font-size: 1.1em;
}

.start-btn:hover {
  background-color: #daf5eb;
}

.startTestBtn {
  width: 250px !important;
  height: 40px !important;
  padding: 2px !important;
  margin: auto;
  border-radius: 10px;
  background-color: #574596 !important;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.12;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.startTest .start-btn {
  font-size: 10pt;
}

.deActiveQuestions {
  display: none;
}
.questionImage{
  /*text-align: center;*/
}
.questionImage img{
  width: 50%;
  border-radius: 5px;
  margin: 0px auto;
}

.testBtn {
  height: 35px !important;
  /*background: #20b770 !important;*/
  color: #3CBE8B;
  border-radius: 50%;
  transition: background-color .3s linear;
  font-weight: bolder;
  font-size: 10pt;
}

.questionsStep {
  padding: 20px;
}

.actionArea {
  position: absolute;
  bottom: 20px;
  right: 20px;
  left: 20px;
}

.registerLogin {
  display: none;
}

.gotoInterpretation {
  display: none;
}

.titleTest {
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: center;
  color: #1c53c1;
  margin-top: 30px;
}

.descTest {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  width: 60%;
  margin: 0px auto;
}

.testInfo {
  width: 40%;
  margin: 40px auto;
}

.testInfoTitle {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.03;
  letter-spacing: normal;
  color: #000;
}

.testInfoDetails {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: center;
  color: #1c53c1;
}

.questionsArea {
  width: 80%;
  margin: 0px auto;
  display: none;
  padding: 20px;
  min-height: 500px;
  background: white;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  position: relative;
}

.loaderArea {

}


</style>